import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'

import Layout from '../components/layout'
import SEO from '../components/seo'

import explorer1 from '../images/explorer-1.jpg'
import explorer2 from '../images/explorer-2.jpg'
import explorer3 from '../images/explorer-3.jpg'
import PageHeader from '../components/PageHeader'

const EXPLORER_ARTICLES = [
  {
    title: 'Recommended Books',
    image: explorer1,
    link: '/recommended-books',
  },
  {
    title: 'Reports and\nInfographics',
    image: explorer3,
    link: '/infographics-reports',
  },
  {
    title: 'Longevity\n Trends',
    image: explorer2,
    link: '/trends',
  },
]

const LongevityExplorerPage = () => (
  <Layout>
    <SEO title="Longevity Explorer" keywords={[`longevity`]} />

    <PageHeader
      titles={['Longevity Explorer']}
      descriptions={[
        `To encapsulate the most relevant and current trends in healthy
        longevity, we are continuously assembling resources in written, audio,
        and visual format for you to explore.`,
      ]}
    />

    <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto my-24 lg:my-32 font-serif text-blue-dianne">
      <div className="lg:flex mt-24">
        {EXPLORER_ARTICLES.map(({ title, image, link }, index) => (
          <div
            key={index}
            className={classnames('mb-16 lg:mb-0 lg:w-1/3 flex-shrink-0', {
              'lg:mr-12': index !== EXPLORER_ARTICLES.length - 1,
            })}
          >
            <Link to={link} className="block no-underline">
              <img src={image} alt="" />
            </Link>
            <h2 className="font-serif font-normal mt-10 mx-8 lg:mx-0">
              <Link to={link} className="no-underline text-blue-dianne text-xl">
                {title.split('\n')[0]}
                <br />
                {title.split('\n')[1]}
              </Link>
            </h2>
            <p className="mt-8 font-sans text-gothic text-sm mx-8 lg:mx-0">
              <Link
                to={link}
                className="no-underline hover:underline text-gothic uppercase"
              >
                Read more
              </Link>
            </p>
          </div>
        ))}
      </div>
    </div>
  </Layout>
)

export default LongevityExplorerPage
